import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar'
import { useParams } from "react-router-dom"
import { collection, query, onSnapshot, doc, setDoc, addDoc, getDoc, getDocs, deleteDoc, updateDoc, where } from 'firebase/firestore';
import { Button } from '@mui/material';
import { db } from '../../firebase'
import { useNavigate } from 'react-router-dom'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { View, Page, Text, Image, Document, StyleSheet, Font } from "@react-pdf/renderer";
import logo from '../assets/logo.png'
import { PDFDownloadLink } from "@react-pdf/renderer";
import { grey } from '@mui/material/colors';
import barcode from '../assets/qrcode.png';
import Vt323 from '../assets/VT323-Regular.ttf';
import moment from 'moment';


function MeasurementDetail() {
    const navigate = useNavigate();
    const { id } = useParams();
    const { Mid } = useParams();
    const [message, setmessage] = useState([]);
    const [detail, setdetail] = useState([]);
    var fileN;


    const getDetails = () => {

        try {
            const ref = query(doc(db, 'client', id, 'measurement', Mid));
            onSnapshot(ref, (doc) => {
                setmessage(doc.data())
            });
            const ref2 = query(doc(db, 'client', id));
            onSnapshot(ref2, (doc) => {
                setdetail(doc.data())
            })

        }
        catch (e) {
            console.log(e)
        }

    }


    useEffect(() => {
        getDetails();
    }, [])

    function PDFFile() {


        Font.register({ family: 'Roboto', src: Vt323, format: "truetype", });
        const styles = StyleSheet.create({

            address: {
                marginLeft: 10,
                fontSize: 10,
                fontFamily: "Roboto",
            },


            flex: {

                margin: 3,
                display: 'flex'


            },
            block: {
                display: 'block'

            },
            row: {
                fontFamily: "Roboto",
                marginLeft: 0,
                marginRight: 'auto',
                borderWidth: 0.3,
                width: '80%',
                borderRightColor: 'gray',
                borderLeftColor: 'gray',
                flexDirection: 'row',
                borderBottomColor: 'gray',
                borderBottomWidth: 0.3,
                alignItems: 'center',
                fontSize: 12,

            },
            description: {
                borderWidth: 0.3,
                width: '50%',
                textAlign: 'left',
                // uppercase
                textTransform: 'uppercase',
                paddingLeft: 8,
            },
            qty: {
                borderWidth: 0.3,
                width: '50%',

                textAlign: 'right',
                paddingRight: 5,
            },
            note: {
                borderWidth: 0,
                width: '50%',
                textAlign: 'left',
                // uppercase
                textTransform: 'uppercase',
                paddingLeft: 8,
            },
            rate: {
                borderWidth: 0.3,
                width: '20%',


                textAlign: 'right',
                paddingRight: 5,
            },

            left: {
                width: '50%',//<- working alternative

            },

            right: {
                padding: 5,
                width: '49%', //<- working alternative

            },
            body: {

            },
            title: {
                fontSize: 14,
                textAlign: "left",
                fontWeight: 'bold',
                color: "#1f1f1f",
                margin: 0,
                lineHeight: 0.4,
                fontFamily: "Roboto",
                marginBottom: 10,
            },
            text: {
                margin: 12,
                fontSize: 14,
                textAlign: "justify",


            },
            image: {
                marginLeft: 0,
                width: 140,
                margin: 0,
                padding: 0,
                lineHeight: 0.4
            },
            img: {
                marginLeft: 2,
                width: '25%',
                marginBottom: 2
            },
            header: {
                fontSize: 14,
                marginBottom: 20,
                textAlign: "center",
                color: "grey",
            },

            th: {

                padding: 0,
                marginTop: 0,
                marginBottom: 0,
                fontSize: 12,
                marginLeft: 2,
                fontWeight: "bold",
                color: 'black',
                lineHeight: 0.7,
                fontFamily: "Roboto",


            },
            tr: {

                fontSize: 12,
                color: 'black',
                marginLeft: 2,
                fontWeight: 'medium',
                fontFamily: "Roboto",

            },
            thopla: {
                color: 'gray',
                marginTop: 0,
                paddingTop: 0,
                fontSize: 8,
                fontFamily: "Roboto",

            },
            black: {
                backgroundColor: 'white',
                color: 'black',
                height: 10,
                fontSize: 10,
                fontFamily: "Roboto",
            },
            b: {
                marginLeft: '',
                marginRight: '',
            },
            top: {
                marginTop: 7
            }

        });






        return (
            <Document>
                <Page size='A6' style={styles.body}>


                    <Text style={styles.flex}>
                        <Image style={styles.image} src={logo} />
                    </Text>
                    <Text style={styles.title} fixed>City Center Block C1, Birtamode</Text>

                    <Text style={styles.thopla}>*****************************************************************************************</Text>
                    <View style={styles.width}>
                        <Text style={styles.th}>
                            Client's Measurement Details
                        </Text>
                        <Text style={styles.thopla}>*************************************************************************************</Text>
                        <Text style={styles.tr}>
                            NAME: {detail.name.toUpperCase()}
                        </Text>
                        <Text style={styles.tr}>
                            ADDRESS: {detail.address.toUpperCase()}
                        </Text>
                        <Text style={styles.tr}>
                            CONTACT: {detail.contact}
                        </Text>
                        <Text style={styles.tr}>
                            CLIENT ID:
                            ***********
                            {
                                // show only last 15 digits of id
                                id.slice(-9)
                            }
                        </Text>
                        <View style={styles.top}>
                            {/* <View style={styles.row} >
                                <Text style={styles.description}>Parameter</Text>
                                <Text style={styles.qty}>Value</Text>
                                <Text style={styles.rate}>Unit</Text>

                            </View> */}
                            <View style={styles.row} >
                                <Text style={styles.description}>Type</Text>
                                <Text style={styles.qty}>{message.type.toUpperCase()}</Text>
                                {/* <Text style={styles.rate}></Text> */}

                            </View>

                            {message.len != '' && <View style={styles.row} >
                                <Text style={styles.description}>Length</Text>
                                <Text style={styles.qty}>{message.len}</Text>
                                {/* <Text style={styles.rate}>in</Text> */}

                            </View>}
                            {message.chest != '' && <View style={styles.row} >
                                <Text style={styles.description}>Chest</Text>
                                <Text style={styles.qty}>{message.chest}</Text>


                            </View>}
                            {message.tummy != '' && <View style={styles.row} >
                                <Text style={styles.description}>Tummy</Text>
                                <Text style={styles.qty}>{message.tummy}</Text>


                            </View>}
                            {message.hip != '' && <View style={styles.row} >
                                <Text style={styles.description}>Hip</Text>
                                <Text style={styles.qty}>{message.hip}</Text>


                            </View>}
                            {message.shoulder != '' && <View style={styles.row} >
                                <Text style={styles.description}>Shoulder</Text>
                                <Text style={styles.qty}>{message.shoulder}</Text>


                            </View>}
                            {message.sleeves != '' && <View style={styles.row} >
                                <Text style={styles.description}>Sleeves</Text>
                                <Text style={styles.qty}>{message.sleeves}</Text>


                            </View>}
                            {message.back != '' && <View style={styles.row} >
                                <Text style={styles.description}>Back</Text>
                                <Text style={styles.qty}>{message.back}</Text>


                            </View>}


                            {message.collar != '' && <View style={styles.row} >
                                <Text style={styles.description}>Collar</Text>
                                <Text style={styles.qty}>{message.collar}</Text>


                            </View>}

                            {message.wrist != '' && <View style={styles.row} >
                                <Text style={styles.description}>Cuff</Text>
                                <Text style={styles.qty}>{message.wrist}</Text>


                            </View>}
                            {message.waist != '' && <View style={styles.row} >
                                <Text style={styles.description}>Waist</Text>
                                <Text style={styles.qty}>{message.waist}</Text>


                            </View>}
                            {message.botlen != '' && <View style={styles.row} >
                                <Text style={styles.description}>Length bottom</Text>
                                <Text style={styles.qty}>{message.botlen}</Text>


                            </View>}
                            {message.calf != '' && <View style={styles.row} >
                                <Text style={styles.description}>Calf</Text>
                                <Text style={styles.qty}>{message.calf}</Text>


                            </View>}
                            {message.knee != '' && <View style={styles.row} >
                                <Text style={styles.description}>Knee</Text>
                                <Text style={styles.qty}>{message.knee}</Text>


                            </View>}

                            {message.thigh != '' && <View style={styles.row} >
                                <Text style={styles.description}>Thigh</Text>
                                <Text style={styles.qty}>{message.thigh}</Text>


                            </View>}
                            {message.high != '' && <View style={styles.row} >
                                <Text style={styles.description}>High</Text>
                                <Text style={styles.qty}>{message.high}</Text>
                                {/* <Text style={styles.rate}>in</Text> */}

                            </View>}
                            {message.note != '' && <View style={styles.row} >
                                <Text style={styles.note}>Note</Text>
                                <Text style={styles.qty}>{message.note.slice(0, 110)}</Text>
                                {/* <Text style={styles.rate}>in</Text> */}

                            </View>}
                        </View>
                        {/* <Text style={styles.th}>Parameters</Text>
            <Text style={styles.thopla}>---------------------------------------------------------</Text>
            <Text style={styles.tr}>Type:   {message.type}</Text> */}


                        {/* <View style={styles.row}>
        <View style={styles.left}>
        <Text style={styles.tr}>Length:   {message.len} in</Text>
          
        </View>
        <View style={styles.right}>
        <Text style={styles.tr}>Chest:   {message.chest} in</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View  style={styles.left}>
        <Text style={styles.tr}>Tummy:   {message.tummy} in</Text>
          
        </View>
        <View  style={styles.right}>
        <Text style={styles.tr}>Hip:   {message.hip} in</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View  style={styles.left}>
        <Text style={styles.tr}>Shoulder:   {message.shoulder} in</Text>
          
        </View>
        <View  style={styles.right}>
        <Text style={styles.tr}>Collar:   {message.collar} in</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View  style={styles.left}>
        <Text style={styles.tr}>Back:   {message.back} in</Text>
          
        </View>
        <View style={styles.right}>
        <Text style={styles.tr}>Wrist:   {message.wrist} in</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.left}>
        <Text style={styles.tr}>Knee:  {message.knee} in</Text>
          
        </View>
        <View style={styles.right}>
        <Text style={styles.tr}>Calf:   {message.calf} in</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.left}>
        <Text style={styles.tr}>Waist:   {message.waist} in</Text>  
          
        </View>
        <View style={styles.right}>
        <Text style={styles.tr}>Thigh:   {message.thigh} in</Text>
        </View>
      </View>
     < Text style={styles.tr}>Note:   {message.note}</Text>  */}





                        {/* <Text style={styles.tr}>Length:   {message.len} in</Text>
            <Text style={styles.tr}>Chest:   {message.chest} in</Text>
            <Text style={styles.tr}>Tummy:   {message.tummy} in</Text>
            <Text style={styles.tr}>Shoulder:   {message.shoulder} in</Text>
           
            <Text style={styles.tr}>Collar:   {message.collar} in</Text>
            <Text style={styles.tr}>Hip:   {message.hip} in</Text>
            <Text style={styles.tr}>Back:   {message.back} in</Text>
            <Text style={styles.tr}>Wrist:   {message.wrist} in</Text>
            <Text style={styles.tr}>Calf:   {message.calf} in</Text>
            <Text style={styles.tr}>Knee:   {message.knee} in</Text>
            <Text style={styles.tr}>Waist:   {message.waist} in</Text>    
            <Text style={styles.tr}>Thigh:   {message.thigh} in</Text> */}
                        {/* <Text style={styles.thopla}>-----------------------------------------------------------------------------------------------</Text> */}
                        <Image style={styles.img} src={barcode} />
                    </View>

                    <View style={styles.black}>
                        <Text style={styles.b}>
                            Contact us at +977-9801444750.
                        </Text>
                    </View>
                    <View style={styles.black}>
                        <Text style={styles.b}>
                            Powered by LinkBinary (https://linkbinary.com)
                        </Text>
                    </View>





                </Page>
            </Document>
        )
    }

    if (message.type === "shirt") {
        fileN = detail.name.replaceAll(' ', '-') + '-' + detail.contact + '-' + message.type + "-" + moment(message.dateadded).format('MM/DD');
        fileN = fileN.toString();
        console.log(fileN);

        return (

            <Sidebar>
                <div className="flex">
                    <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}><ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} /></div>
                    <div className="block">
                        <div className="text-xl text-black bold">Measurement Details </div>
                        <p className='mb-3 text-sm italic text-black dark:text-white-rgba-6 text-opacity-40'>
                            Client's {message.type}
                        </p>
                    </div>

                </div>
                <div className='w-1/2 p-5 ml-auto mr-auto font-medium text-left rounded-sm cont text-centre'>
                    <div className='p-3'><span className='font-medium text-black'>Type:</span><span className='pl-1 text-lg font-bold text-gray-900 '>{message.type}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Length/
                        लम्बाइ:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.len}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Chest/छाती:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.chest}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Tummy/पेट:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.tummy}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Hip:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.hip}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Shoulder/कुम:</span ><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.shoulder}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Sleeves/बाउला:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.sleeves}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Wrist/नाडी:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.wrist}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Collar/कलर:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.collar}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Note:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.note}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Reference:</span></div>
                    <img src={message.img} clasName='rounded w-1/4 ' alt="" srcset="" />
                    <PDFDownloadLink document={<PDFFile />} fileName={fileN}>
                        {({ loading }) =>
                            loading ? (
                                <button className="px-3 py-1 m-3 ml-auto mr-auto text-white rounded bg-slate-700 hover:bg-slate-900 ">Loading Document...</button>
                            ) : (
                                <button className="px-3 py-1 m-3 ml-auto mr-auto text-white rounded bg-slate-700 hover:bg-slate-900 ">Download Pdf</button>
                            )
                        }
                    </PDFDownloadLink>
                </div>





            </Sidebar>

        )
    }
    else if (message.type === "coat") {
        fileN = detail.name.replaceAll(' ', '-') + '-' + detail.contact + '-' + message.type + "-" + moment(message.dateadded).format('MM/DD');
        fileN = fileN.toString();
        console.log(fileN);
        return (
            <Sidebar>
                <div className="flex">
                    <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}><ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} /></div>
                    <div className="block">
                        <div className="text-xl text-black bold">Measurement Details </div>
                        <p className='mb-3 text-sm italic text-black dark:text-white-rgba-6 text-opacity-40'>
                            Client's {message.type}
                        </p>
                    </div>

                </div>
                <div className='w-1/2 p-5 ml-auto mr-auto font-medium rounded-sm cont text-centre'>
                    <div className='p-3'><span className='font-medium text-black'>Type:</span><span className='pl-1 text-lg font-bold text-gray-900 '>{message.type}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Length/
                        लम्बाइ:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.len}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Chest/छाती:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.chest}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Tummy/पेट:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.tummy}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Hip:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.hip}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Shoulder:</span ><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.shoulder}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Sleeves/बाउला:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.sleeves}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Back:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.back}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Collar/कलर:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.collar}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Note:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.note}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Reference:</span></div>
                    <img src={message.img} clasName='rounded w-1/4 ' alt="" srcset="" />
                    <PDFDownloadLink document={<PDFFile />} fileName={fileN}>
                        {({ loading }) =>
                            loading ? (
                                <button className="px-3 py-1 m-3 ml-auto mr-auto text-white rounded bg-slate-700 hover:bg-slate-900 ">Loading Document...</button>
                            ) : (
                                <button className="px-3 py-1 m-3 ml-auto mr-auto text-white rounded bg-slate-700 hover:bg-slate-900 ">Download Pdf</button>
                            )
                        }
                    </PDFDownloadLink>
                </div>




            </Sidebar>
        )
    }
    else if (message.type === "halfcoat") {
        fileN = detail.name.replaceAll(' ', '-') + '-' + detail.contact + '-' + message.type + "-" + moment(message.dateadded).format('MM/DD');
        fileN = fileN.toString();
        console.log(fileN);
        return (
            <Sidebar>
                <div className="flex">
                    <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}><ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} /></div>
                    <div className="block">
                        <div className="text-xl text-black bold">Measurement Details </div>
                        <p className='mb-3 text-sm italic text-black dark:text-white-rgba-6 text-opacity-40'>
                            Client's {message.type}
                        </p>
                    </div>

                </div>
                <div className='w-1/2 p-5 ml-auto mr-auto font-medium rounded-sm cont text-centre'>
                    <div className='p-3'><span className='font-medium text-black'>Type:</span><span className='pl-1 text-lg font-bold text-gray-900 '>{message.type}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Length/
                        लम्बाइ:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.len}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Chest/छाती:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.chest}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Tummy/पेट:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.tummy}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Hip:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.hip}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Shoulder:</span ><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.shoulder}</span></div>

                    <div className='p-3'><span className='font-medium text-black'>Collar/कलर:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.collar}</span></div>

                    <div className='p-3'><span className='font-medium text-black'>Note:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.note}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Reference:</span></div>
                    <img src={message.img} clasName='rounded w-1/4 ' alt="" srcset="" />
                    <PDFDownloadLink document={<PDFFile />} fileName={fileN}>
                        {({ loading }) =>
                            loading ? (
                                <button className="px-3 py-1 m-3 ml-auto mr-auto text-white rounded bg-slate-700 hover:bg-slate-900 ">Loading Document...</button>
                            ) : (
                                <button className="px-3 py-1 m-3 ml-auto mr-auto text-white rounded bg-slate-700 hover:bg-slate-900 ">Download Pdf</button>
                            )
                        }
                    </PDFDownloadLink>
                </div>



            </Sidebar>
        )
    }
    else if (message.type === "kurtha") {
        fileN = detail.name.replaceAll(' ', '-') + '-' + detail.contact + '-' + message.type + "-" + moment(message.dateadded).format('MM/DD');
        fileN = fileN.toString();
        console.log(fileN);
        return (
            <Sidebar>
                <div className="flex">
                    <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}><ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} /></div>
                    <div className="block">
                        <div className="text-xl text-black bold">Measurement Details </div>
                        <p className='mb-3 text-sm italic text-black dark:text-white-rgba-6 text-opacity-40'>
                            Client's {message.type}
                        </p>
                    </div>

                </div>
                <div className='w-1/2 p-5 ml-auto mr-auto font-medium rounded-sm cont text-centre'>
                    <div className='p-3'><span className='font-medium text-black'>Type:</span><span className='pl-1 text-lg font-bold text-gray-900 '>{message.type}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Length/
                        लम्बाइ:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.len}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Chest/छाती:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.chest}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Tummy/पेट:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.tummy}</span></div>

                    <div className='p-3'><span className='font-medium text-black'>Shoulder:</span ><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.shoulder}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Sleeves/बाउला:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.sleeves}</span></div>

                    <div className='p-3'><span className='font-medium text-black'>Collar/कलर:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.collar}</span></div>

                    <div className='p-3'><span className='font-medium text-black'>suruwal Length/
                        लम्बाइ:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.botlen}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Waist/कम्मर:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.waist}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Thigh/जांघ:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.thigh}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Hip:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.hip}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>High:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.high}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Bottom:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.bottom}</span></div>




                    <div className='p-3'><span className='font-medium text-black'>Note:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.note}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Reference:</span></div>
                    <img src={message.img} clasName='rounded w-1/4 ' alt="" srcset="" />
                    <PDFDownloadLink document={<PDFFile />} fileName={fileN}>
                        {({ loading }) =>
                            loading ? (
                                <button className="px-3 py-1 m-3 ml-auto mr-auto text-white rounded bg-slate-700 hover:bg-slate-900 ">Loading Document...</button>
                            ) : (
                                <button className="px-3 py-1 m-3 ml-auto mr-auto text-white rounded bg-slate-700 hover:bg-slate-900 ">Download Pdf</button>
                            )
                        }
                    </PDFDownloadLink>
                </div>



            </Sidebar>
        )
    }
    else if (message.type === "dawra") {
        fileN = detail.name.replaceAll(' ', '-') + '-' + detail.contact + '-' + message.type + "-" + moment(message.dateadded).format('MM/DD');
        fileN = fileN.toString();
        console.log(fileN);
        return (
            <Sidebar>
                <div className="flex">
                    <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}><ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} /></div>
                    <div className="block">
                        <div className="text-xl text-black bold">Measurement Details </div>
                        <p className='mb-3 text-sm italic text-black dark:text-white-rgba-6 text-opacity-40'>
                            Client's {message.type}
                        </p>
                    </div>

                </div>
                <div className='w-1/2 p-5 ml-auto mr-auto font-medium rounded-sm cont text-centre'>
                    <div className='p-3'><span className='font-medium text-black'>Type:</span><span className='pl-1 text-lg font-bold text-gray-900 '>{message.type}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Length/
                        लम्बाइ:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.len}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Chest/छाती:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.chest}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Tummy/पेट:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.tummy}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Hip:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.hip}</span></div>

                    <div className='p-3'><span className='font-medium text-black'>Sleeves/बाउला:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.sleeves}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Collar/कलर:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.collar}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Wrist/नाडी:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.wrist}</span></div>

                    <div className='p-3'><span className='font-medium text-black'>suruwal Length/
                        लम्बाइ:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.botlen}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Calf:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.calf}</span></div>

                    <div className='p-3'><span className='font-medium text-black'>Bottom:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.bottom}</span></div>


                    <div className='p-3'><span className='font-medium text-black'>Note:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.note}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Reference:</span></div>
                    <img src={message.img} clasName='rounded w-1/4 ' alt="" srcset="" />
                    <PDFDownloadLink document={<PDFFile />} fileName={fileN}>
                        {({ loading }) =>
                            loading ? (
                                <button className="px-3 py-1 m-3 ml-auto mr-auto text-white rounded bg-slate-700 hover:bg-slate-900 ">Loading Document...</button>
                            ) : (
                                <button className="px-3 py-1 m-3 ml-auto mr-auto text-white rounded bg-slate-700 hover:bg-slate-900 ">Download Pdf</button>
                            )
                        }
                    </PDFDownloadLink>
                </div>



            </Sidebar>
        )
    }
    else if (message.type === "waistcoat") {
        fileN = detail.name.replaceAll(' ', '-') + '-' + detail.contact + '-' + message.type + "-" + moment(message.dateadded).format('MM/DD');
        fileN = fileN.toString();
        console.log(fileN);
        return (
            <Sidebar>
                <div className="flex">
                    <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}><ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} /></div>
                    <div className="block">
                        <div className="text-xl text-black bold">Measurement Details </div>
                        <p className='mb-3 text-sm italic text-black dark:text-white-rgba-6 text-opacity-40'>
                            Client's {message.type}
                        </p>
                    </div>

                </div>
                <div className='w-1/2 p-5 ml-auto mr-auto font-medium rounded-sm cont text-centre'>
                    <div className='p-3'><span className='font-medium text-black'>Type:</span><span className='pl-1 text-lg font-bold text-gray-900 '>{message.type}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Length/
                        लम्बाइ:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.len}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Chest/छाती:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.chest}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Tummy/पेट:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.tummy}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Hip:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.hip}</span></div>

                    <div className='p-3'><span className='font-medium text-black'>Note:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.note}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Reference:</span></div>
                    <img src={message.img} clasName='rounded w-1/4 ' alt="" srcset="" />
                    <PDFDownloadLink document={<PDFFile />} fileName={fileN}>
                        {({ loading }) =>
                            loading ? (
                                <button className="px-3 py-1 m-3 ml-auto mr-auto text-white rounded bg-slate-700 hover:bg-slate-900 ">Loading Document...</button>
                            ) : (
                                <button className="px-3 py-1 m-3 ml-auto mr-auto text-white rounded bg-slate-700 hover:bg-slate-900 ">Download Pdf</button>
                            )
                        }
                    </PDFDownloadLink>
                </div>



            </Sidebar>
        )
    }

    else if (message.type === "pant") {
        fileN = detail.name.replaceAll(' ', '-') + '-' + detail.contact + '-' + message.type + "-" + moment(message.dateadded).format('MM/DD');
        fileN = fileN.toString();
        console.log(fileN);
        return (
            <Sidebar>
                <div className="flex">
                    <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}><ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} /></div>
                    <div className="block">
                        <div className="text-xl text-black bold">Measurement Details </div>
                        <p className='mb-3 text-sm italic text-black dark:text-white-rgba-6 text-opacity-40'>
                            Client's {message.type}
                        </p>
                    </div>

                </div>
                <div className='w-1/2 p-5 ml-auto mr-auto font-medium rounded-sm cont text-centre'>
                    <div className='p-3'><span className='font-medium text-black'>Type:</span><span className='pl-1 text-lg font-bold text-gray-900 '>{message.type}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Length/
                        लम्बाइ:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.len}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Waist/कम्मर:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.waist}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Hip:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.hip}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Thigh/जांघ:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.thigh}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>High:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.high}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Bottom:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.bottom}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Knee/घुँडा:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.knee}</span></div>

                    <div className='p-3'><span className='font-medium text-black'>Note:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.note}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Reference:</span></div>
                    <img src={message.img} clasName='rounded w-1/4 ' alt="" srcset="" />
                    <PDFDownloadLink document={<PDFFile />} fileName={fileN}>
                        {({ loading }) =>
                            loading ? (
                                <button className="px-3 py-1 m-3 ml-auto mr-auto text-white rounded bg-slate-700 hover:bg-slate-900 ">Loading Document...</button>
                            ) : (
                                <button className="px-3 py-1 m-3 ml-auto mr-auto text-white rounded bg-slate-700 hover:bg-slate-900 ">Download Pdf</button>
                            )
                        }
                    </PDFDownloadLink>
                </div>



            </Sidebar>
        )
    }


    else if (message.type === "suruwald") {
        fileN = detail.name.replaceAll(' ', '-') + '-' + detail.contact + '-' + message.type + "-" + moment(message.dateadded).format('MM/DD');
        fileN = fileN.toString();
        console.log(fileN);
        return (
            <Sidebar>
                <div className="flex">
                    <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}><ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} /></div>
                    <div className="block">
                        <div className="text-xl text-black bold">Measurement Details </div>
                        <p className='mb-3 text-sm italic text-black dark:text-white-rgba-6 text-opacity-40'>
                            Client's {message.type}
                        </p>
                    </div>

                </div>
                <div className='w-1/2 p-5 ml-auto mr-auto font-medium rounded-sm cont text-centre'>
                    <div className='p-3'><span className='font-medium text-black'>Type:</span><span className='pl-1 text-lg font-bold text-gray-900 '>{message.type}</span></div>


                    <div className='p-3'><span className='font-medium text-black'>Note:</span><span className='px-1 pl-1 text-lg font-bold text-gray-900 '>{message.note}</span></div>
                    <div className='p-3'><span className='font-medium text-black'>Reference:</span></div>
                    <img src={message.img} clasName='rounded w-1/4 ' alt="" srcset="" />
                    <PDFDownloadLink document={<PDFFile />} fileName={fileN}>
                        {({ loading }) =>
                            loading ? (
                                <button className="px-3 py-1 m-3 ml-auto mr-auto text-white rounded bg-slate-700 hover:bg-slate-900 ">Loading Document...</button>
                            ) : (
                                <button className="px-3 py-1 m-3 ml-auto mr-auto text-white rounded bg-slate-700 hover:bg-slate-900 ">Download PDF</button>
                            )
                        }
                    </PDFDownloadLink>
                </div>



            </Sidebar>
        )
    }




    else {
        return (
            <>Error.</>
        )
    }



}









export default MeasurementDetail