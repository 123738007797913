import React from 'react'
import {Route, Routes,} from 'react-router-dom';
import Login from './Login';
import PrivateRoutes from './ProtectedRoute';
import Dashboard from './admin/components/Dashboard';
import Home from './site/components/Home/Home';
import Client from './admin/components/ClientAdmin';

import Addmeasurement from './admin/components/Addmeasurement';
import Typeadd from './admin/components/typeadd';
import Viewclient from './admin/components/ViewClient';
import MeasurementDetail from './admin/components/Measurementdetail';
import Productsformal from './site/components/Products/Productsformal';
import Productstraditional from './site/components/Products/Ptraditional';
import Setting from './admin/components/settings';
import UpdateMeasurement from './admin/components/UpdateMeasurement';
function SiteRoutes() {
  return (
    <Routes>
        <Route path="/" element={<Login/>}/> 
        
        
        <Route path="/login" element={<Login/>} />
        <Route path="/admin" element={<PrivateRoutes><Dashboard /></PrivateRoutes>} />
        <Route path="/admin/client" element={<PrivateRoutes><Client/></PrivateRoutes>} />
        <Route path="/admin/setting" element={<PrivateRoutes><Setting/></PrivateRoutes>} />
        <Route path="/admin/measurement/:id" element={<PrivateRoutes><Addmeasurement/></PrivateRoutes>} />
        <Route path="/admin/measurement/view/:id" element={<PrivateRoutes><Viewclient/></PrivateRoutes>} />
        <Route path="/admin/measurement/:id/:type" element={<PrivateRoutes><Typeadd/></PrivateRoutes>} />
        <Route path="/admin/measurement/viewmeasurement/:id/:Mid" element={<PrivateRoutes><MeasurementDetail/></PrivateRoutes>} />
        <Route path="/admin/measurement/updatemeasurement/:id/:Mid" element={<PrivateRoutes><UpdateMeasurement/></PrivateRoutes>} />
        

        
    </Routes>
  )
}

export default SiteRoutes