import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar'
import { useParams } from "react-router-dom"
import { collection, query, onSnapshot, doc, setDoc, addDoc, getDoc, getDocs, deleteDoc, updateDoc, where } from 'firebase/firestore';
import { Button, Alert } from '@mui/material';
import { db } from '../../firebase'
import { Link, useNavigate } from 'react-router-dom'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { Page, Text, Image, Document, StyleSheet } from "@react-pdf/renderer";
import logo from '../assets/logo.png'
import { PDFDownloadLink } from "@react-pdf/renderer";
import { grey } from '@mui/material/colors';

function UpdateMeasurement() {
    const navigate = useNavigate();
    const { id } = useParams();
    const { Mid } = useParams();
    const [message, setmessage] = useState([]);
    const [detail, setdetail] = useState([]);



    const [length, setLength] = React.useState('');
    const [wrist, setWrist] = React.useState('');
    const [chest, setChest] = React.useState('');
    const [tummy, setTummy] = React.useState('');
    const [hip, setHip] = React.useState('');
    const [shoulder, setShoulder] = React.useState('');
    const [sleeves, setSleeves] = React.useState('');
    const [collar, setCollar] = React.useState('');
    const [note, setNote] = React.useState('');
    const [back, setBack] = React.useState('');
    const [f, setF] = React.useState(false);


    const [disable, setdisable] = React.useState('');
    const [thigh, setThigh] = React.useState('');
    const [high, setHigh] = React.useState("");
    const [bottom, setBottom] = React.useState("");
    const [knee, setKnee] = React.useState('');
    const [waist, setWaist] = React.useState('');
    const [calf, setCalf] = React.useState('');


    const [addedIt, setAddedIt] = useState(false);
    var typ = '';

    const [percent, setPercent] = useState(0);



    const handleBack = (event) => {
        setBack(event.target.value);

    }
    const handleLength = (event) => {
        setLength(event.target.value);

    }



    const handleChest = (event) => {
        setChest(event.target.value);
    }
    const handleTummy = (event) => {
        setTummy(event.target.value);
    }
    const handleHip = (event) => {
        setHip(event.target.value);

    }
    const handleCalf = (event) => {
        setCalf(event.target.value);

    }



    const handleShoulder = (event) => {
        setShoulder(event.target.value);
    }
    const handleSleeves = (event) => {
        setSleeves(event.target.value);
    }
    const handleCollar = (event) => {
        setCollar(event.target.value);
    }
    const handleNote = (event) => {
        setNote(event.target.value);
    }
    const handleWrist = (event) => {
        setWrist(event.target.value);
    }
    const handleThigh = (event) => {
        setThigh(event.target.value);
    }
    const handleHigh = (event) => {
        setHigh(event.target.value);
    }
    const handleBottom = (event) => {
        setBottom(event.target.value);
    }
    const handleKnee = (event) => {
        setKnee(event.target.value);
    }
    const handleWaist = (event) => {
        setWaist(event.target.value);
    }


    const getDetails = () => {

        try {

            const ref = query(doc(db, 'client', id, 'measurement', Mid));
            onSnapshot(ref, (doc) => {
                setmessage(doc.data());
                setF(true);
            });

            console.log(message.type)

        }
        catch (e) {
            console.log(e)
        }

        setLength(message.len);
        setBack(message.back);
        setBottom(message.bottom);
        setCalf(message.calf);
        setChest(message.chest);
        setCollar(message.collar);
        setHigh(message.high);
        setHip(message.hip);
        setKnee(message.knee);
        setShoulder(message.shoulder);
        setSleeves(message.sleeves);
        setThigh(message.thigh);
        setTummy(message.tummy);
        setWaist(message.waist);
        setWrist(message.wrist);
        setNote(message.note);

    }



    useEffect(() => {
        getDetails();

    }, [f])

    const editMeasurement = async () => {

        try {
            await setDoc(doc(db, 'client', id, 'measurement', Mid), {

                len: length,
                chest: chest,
                tummy: tummy,
                hip: hip,
                shoulder: shoulder,
                sleeves: sleeves,
                collar: collar,
                back: back,
                thigh: thigh,
                high: high,
                bottom: bottom,
                knee: knee,
                wrist: wrist,
                waist: waist,
                calf: calf,
                note: note,


            },
                { merge: true }
            );


        } catch (error) {
            console.log(error);
        }
        setAddedIt(true);
        setTimeout(() => {
            setAddedIt(false);
        }, 3000);
    }


    if (message.type === 'shirt') {
        typ = 'shirt';
        return (

            <Sidebar>
                <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}><ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} /></div>
                <div className='flex flex-col items-center justify-center gap-2 p-5 mt-5 mb-5 border rounded dark:border-white-rgba-1'>

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Length/
                        लम्बाइ</label>
                    <input maxLength={50} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6' type='text' value={length} onChange={handleLength} placeholder={message.len} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Chest/छाती</label>
                    <input maxLength={10} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6' type='text' value={chest} onChange={handleChest} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Tummy/पेत</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={tummy} onChange={handleTummy} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Hip/हिप</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={hip} onChange={handleHip} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Shoulder/कुम</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={shoulder} onChange={handleShoulder} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Sleeves/बाउला </label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={sleeves} onChange={handleSleeves} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Collar</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={collar} onChange={handleCollar} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Wrist</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={wrist} onChange={handleWrist} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Note</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={note} onChange={handleNote} />





                    {addedIt && <Alert variant='outlined' className='my-3 text-sm text-green-500'>Updated successfully</Alert>}



                    <div className='flex gap-2'>

                        <div


                            className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkblue hover:bg-linkbluer w-1/8 dark:bg-white-rgba-3 dark:hover:bg-white-rgba-2 hover:bg-black focus:bg-black hover:text-white '
                            onClick={editMeasurement}
                        >
                            Update
                        </div>


                        <div className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkred hover:bg-linkredder w-1/8 hover:bg-black focus:bg-black hover:text-white' onClick={() => navigate(-1)} >Back</div>
                    </div>
                </div>
            </Sidebar>
        )




    }
    else if (message.type === 'coat') {
        typ = 'coat';
        return (

            <Sidebar>
                <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}><ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} /></div>
                <div className='flex flex-col items-center justify-center gap-2 p-5 mt-5 mb-5 border rounded dark:border-white-rgba-1'>

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Length/
                        लम्बाइ</label>
                    <input maxLength={50} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6' type='text' value={length} onChange={handleLength} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Chest/छाती</label>
                    <input maxLength={10} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6' type='text' value={chest} onChange={handleChest} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Tummy/पेत</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={tummy} onChange={handleTummy} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Hip/हिप</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={hip} onChange={handleHip} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Shoulder/कुम</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={shoulder} onChange={handleShoulder} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Sleeves/बाउला </label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={sleeves} onChange={handleSleeves} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Back</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={back} onChange={handleBack} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Collar</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={collar} onChange={handleCollar} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Note</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={note} onChange={handleNote} />



                    {addedIt && <Alert variant='outlined' className='my-3 text-sm text-green-500'>Updated successfully</Alert>}



                    <div className='flex gap-2'>

                        <div


                            className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkblue hover:bg-linkbluer w-1/8 dark:bg-white-rgba-3 dark:hover:bg-white-rgba-2 hover:bg-black focus:bg-black hover:text-white '
                            onClick={editMeasurement}
                        >
                            Update
                        </div>


                        <div className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkred hover:bg-linkredder w-1/8 hover:bg-black focus:bg-black hover:text-white' onClick={() => navigate(-1)}>Back</div>
                    </div>
                </div>
            </Sidebar>
        )




    }

    else if (message.type === 'waistcoat') {
        typ = 'waistcoat';
        return (

            <Sidebar>
                <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}><ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} /></div>
                <div className='flex flex-col items-center justify-center gap-2 p-5 mt-5 mb-5 border rounded dark:border-white-rgba-1'>

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Length/
                        लम्बाइ</label>
                    <input maxLength={50} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6 cont' type='text' value={length} onChange={handleLength} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Chest/छाती</label>
                    <input maxLength={10} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6 cont' type='text' value={chest} onChange={handleChest} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Tummy/पेत</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={tummy} onChange={handleTummy} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Hip/हिप</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={hip} onChange={handleHip} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Note</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={note} onChange={handleNote} />



                    {addedIt && <Alert variant='outlined' className='my-3 text-sm text-green-500'>Updated successfully</Alert>}



                    <div className='flex gap-2'>

                        <div


                            className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkblue hover:bg-linkbluer w-1/8 dark:bg-white-rgba-3 dark:hover:bg-white-rgba-2 hover:bg-black focus:bg-black hover:text-white '
                            onClick={editMeasurement}
                        >
                            Update
                        </div>


                        <div className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkred hover:bg-linkredder w-1/8 hover:bg-black focus:bg-black hover:text-white' onClick={() => navigate(-1)}>Back</div>
                    </div>
                </div>
            </Sidebar>
        )




    }
    else if (message.type === 'kurtha') {
        typ = 'kurtha';

        return (

            <Sidebar>
                <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}><ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} /></div>
                <div className='flex flex-col items-center justify-center gap-2 p-5 mt-5 mb-5 border rounded dark:border-white-rgba-1'>

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Length/
                        लम्बाइ</label>
                    <input maxLength={50} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6 cont' type='text' value={length} onChange={handleLength} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Chest/छाती</label>
                    <input maxLength={10} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6 cont' type='text' value={chest} onChange={handleChest} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Tummy/पेत</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={tummy} onChange={handleTummy} />



                    <label className='text-sm font-bold dark:text-white-rgba-7'>Shoulder/कुम</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={shoulder} onChange={handleShoulder} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Sleeves/बाउला </label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={sleeves} onChange={handleSleeves} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Collar</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={collar} onChange={handleCollar} />


                    <label className='text-sm font-bold dark:text-white-rgba-7'>Note</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={note} onChange={handleNote} />


                    {addedIt && <Alert variant='outlined' className='my-3 text-sm text-green-500'>Updated successfully</Alert>}



                    <div className='flex gap-2'>

                        <div


                            className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkblue hover:bg-linkbluer w-1/8 dark:bg-white-rgba-3 dark:hover:bg-white-rgba-2 hover:bg-black focus:bg-black hover:text-white '
                            onClick={editMeasurement}
                        >
                            Update
                        </div>


                        <div className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkred hover:bg-linkredder w-1/8 hover:bg-black focus:bg-black hover:text-white' onClick={() => navigate(-1)} >Back</div>
                    </div>
                </div>
            </Sidebar>
        )




    }

    else if (message.type === 'dawra') {
        typ = 'dawra';
        return (

            <Sidebar>
                <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}><ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} /></div>
                <div className='flex flex-col items-center justify-center gap-2 p-5 mt-5 mb-5 border rounded dark:border-white-rgba-1'>

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Length/
                        लम्बाइ</label>
                    <input maxLength={50} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6 cont' type='text' value={length} onChange={handleLength} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Chest/छाती</label>
                    <input maxLength={10} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6 cont' type='text' value={chest} onChange={handleChest} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Tummy/पेत</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={tummy} onChange={handleTummy} />



                    <label className='text-sm font-bold dark:text-white-rgba-7'>Shoulder/कुम</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={shoulder} onChange={handleShoulder} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Sleeves/बाउला </label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={sleeves} onChange={handleSleeves} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Collar</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={collar} onChange={handleCollar} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Note</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={note} onChange={handleNote} />

                    {addedIt && <Alert variant='outlined' className='my-3 text-sm text-green-500'>Updated successfully</Alert>}



                    <div className='flex gap-2'>

                        <div


                            className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkblue hover:bg-linkbluer w-1/8 dark:bg-white-rgba-3 dark:hover:bg-white-rgba-2 hover:bg-black focus:bg-black hover:text-white '
                            onClick={editMeasurement}
                        >
                            Update
                        </div>


                        <div className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkred hover:bg-linkredder w-1/8 hover:bg-black focus:bg-black hover:text-white' onClick={() => navigate(-1)} >Back</div>
                    </div>
                </div>
            </Sidebar>
        )




    }

    else if (message.type === 'halfcoat') {
        typ = 'halfcoat';
        return (

            <Sidebar>
                <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}><ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} /></div>
                <div className='flex flex-col items-center justify-center gap-2 p-5 mt-5 mb-5 border rounded dark:border-white-rgba-1'>

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Length/
                        लम्बाइ</label>
                    <input maxLength={50} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6 cont' type='text' value={length} onChange={handleLength} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Chest/छाती</label>
                    <input maxLength={10} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6 cont' type='text' value={chest} onChange={handleChest} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Tummy/पेत</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={tummy} onChange={handleTummy} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Hip/हिप</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={hip} onChange={handleHip} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Shoulder/कुम</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={shoulder} onChange={handleShoulder} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Collar</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={collar} onChange={handleCollar} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Note</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={note} onChange={handleNote} />


                    {addedIt && <Alert variant='outlined' className='my-3 text-sm text-green-500'>Updated successfully</Alert>}



                    <div className='flex gap-2'>

                        <div


                            className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkblue hover:bg-linkbluer w-1/8 dark:bg-white-rgba-3 dark:hover:bg-white-rgba-2 hover:bg-black focus:bg-black hover:text-white '
                            onClick={editMeasurement}
                        >
                            Update
                        </div>


                        <div className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkred hover:bg-linkredder w-1/8 hover:bg-black focus:bg-black hover:text-white' onClick={() => navigate(-1)} >Back</div>
                    </div>
                </div>
            </Sidebar>
        )




    }

    else if (message.type === 'pant') {
        typ = 'pant';
        return (

            <Sidebar>
                <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}><ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} /></div>
                <div className='flex flex-col items-center justify-center gap-2 p-5 mt-5 mb-5 border rounded dark:border-white-rgba-1'>

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Length/
                        लम्बाइ</label>
                    <input maxLength={50} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6' type='text' value={length} onChange={handleLength} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Waist</label>
                    <input maxLength={10} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6' type='text' value={waist} onChange={handleWaist} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Thigh</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={thigh} onChange={handleThigh} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Hip/हिप</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={hip} onChange={handleHip} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>High</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={high} onChange={handleHigh} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Bottom </label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={bottom} onChange={handleBottom} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Knee</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={knee} onChange={handleKnee} />


                    <label className='text-sm font-bold dark:text-white-rgba-7'>Note</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={note} onChange={handleNote} />

                    {addedIt && <Alert variant='outlined' className='my-3 text-sm text-green-500'>Updated successfully</Alert>}



                    <div className='flex gap-2'>

                        <div


                            className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkblue hover:bg-linkbluer w-1/8 dark:bg-white-rgba-3 dark:hover:bg-white-rgba-2 hover:bg-black focus:bg-black hover:text-white '
                            onClick={editMeasurement}
                        >
                            Update
                        </div>

                        <div className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkred hover:bg-linkredder w-1/8 hover:bg-black focus:bg-black hover:text-white' onClick={() => navigate(-1)} >Back</div>
                    </div>
                </div>
            </Sidebar>
        )




    }

    else if (message.type === 'suruwalk') {
        typ = 'suruwalk';
        return (

            <Sidebar>
                <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}><ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} /></div>
                <div className='flex flex-col items-center justify-center gap-2 p-5 mt-5 mb-5 border rounded dark:border-white-rgba-1'>

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Length/
                        लम्बाइ</label>
                    <input maxLength={50} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6' type='text' value={length} onChange={handleLength} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Waist</label>
                    <input maxLength={10} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6' type='text' value={waist} onChange={handleWaist} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Thigh</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={thigh} onChange={handleThigh} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Hip/हिप</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={hip} onChange={handleHip} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>High</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={high} onChange={handleHigh} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Bottom </label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={bottom} onChange={handleBottom} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Note</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={note} onChange={handleNote} />





                    {addedIt && <Alert variant='outlined' className='my-3 text-sm text-green-500'>Updated successfully</Alert>}



                    <div className='flex gap-2'>

                        <div


                            className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkblue hover:bg-linkbluer w-1/8 dark:bg-white-rgba-3 dark:hover:bg-white-rgba-2 hover:bg-black focus:bg-black hover:text-white '
                            onClick={editMeasurement}
                        >
                            Update
                        </div>


                        <div className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkred hover:bg-linkredder w-1/8 hover:bg-black focus:bg-black hover:text-white' onClick={() => navigate(-1)}>Back</div>
                    </div>
                </div>
            </Sidebar>
        )




    }

    else if (message.type === 'suruwald') {
        typ = 'suruwald';
        return (

            <Sidebar>
                <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}><ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} /></div>
                <div className='flex flex-col items-center justify-center gap-2 p-5 mt-5 mb-5 border rounded dark:border-white-rgba-1'>

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Length/
                        लम्बाइ</label>
                    <input maxLength={50} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6' type='text' value={length} onChange={handleLength} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Calf</label>
                    <input maxLength={50} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6' type='text' value={calf} onChange={handleCalf} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Bottom </label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={bottom} onChange={handleBottom} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Note</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={note} onChange={handleNote} />

                    {addedIt && <Alert variant='outlined' className='my-3 text-sm text-green-500'>Updated successfully</Alert>}



                    <div className='flex gap-2'>

                        <div


                            className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkblue hover:bg-linkbluer w-1/8 dark:bg-white-rgba-3 dark:hover:bg-white-rgba-2 hover:bg-black focus:bg-black hover:text-white '
                            onClick={editMeasurement}
                        >
                            Update
                        </div>


                        <div className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkred hover:bg-linkredder w-1/8 hover:bg-black focus:bg-black hover:text-white' onClick={() => navigate(-1)}>Back</div>
                    </div>
                </div>
            </Sidebar>
        )




    }






    else {
        return (
            <>null</>
        )
    }

}

export default UpdateMeasurement