import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar'
import { useParams } from "react-router-dom"
import { collection, query, onSnapshot, doc, setDoc, addDoc, getDoc, getDocs, deleteDoc, updateDoc, where } from 'firebase/firestore';
import { Button, Alert } from '@mui/material';
import { db, storage } from '../../firebase';

import { Link, useNavigate } from "react-router-dom"
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { getDownloadURL, ref, uploadBytesResumable, uploadString } from 'firebase/storage';

function Typeadd() {
    const navigate = useNavigate();
    const { id } = useParams();
    const { type } = useParams();
    const [length, setLength] = React.useState('');
    const [wrist, setWrist] = React.useState('');
    const [chest, setChest] = React.useState('');
    const [tummy, setTummy] = React.useState('');
    const [hip, setHip] = React.useState('');
    const [shoulder, setShoulder] = React.useState('');
    const [sleeves, setSleeves] = React.useState('');
    const [collar, setCollar] = React.useState('');
    const [note, setNote] = React.useState('');
    const [back, setBack] = React.useState('');
    const [image, setImage] = React.useState("");
    const [img, setImg] = React.useState("");
    const [disable, setdisable] = React.useState('');
    const [thigh, setThigh] = React.useState('');
    const [high, setHigh] = React.useState("");
    const [bottom, setBottom] = React.useState("");
    const [knee, setKnee] = React.useState('');
    const [waist, setWaist] = React.useState('');
    const [calf, setCalf] = React.useState('');
    const [botlen, setBotlen] = React.useState('');



    const [addedIt, setAddedIt] = useState(false);
    var typ = '';

    const [percent, setPercent] = useState(0);



    const handleBack = (event) => {
        setBack(event.target.value);

    }

    const handleBotlen = (event) => {
        setBotlen(event.target.value);

    }

    const handleLength = (event) => {
        setLength(event.target.value);

    }



    const handleChest = (event) => {
        setChest(event.target.value);
    }
    const handleTummy = (event) => {
        setTummy(event.target.value);
    }
    const handleHip = (event) => {
        setHip(event.target.value);

    }
    const handleCalf = (event) => {
        setCalf(event.target.value);

    }



    const handleShoulder = (event) => {
        setShoulder(event.target.value);
    }
    const handleSleeves = (event) => {
        setSleeves(event.target.value);
    }
    const handleCollar = (event) => {
        setCollar(event.target.value);
    }
    const handleNote = (event) => {
        setNote(event.target.value);
    }
    const handleWrist = (event) => {
        setWrist(event.target.value);
    }
    const handleThigh = (event) => {
        setThigh(event.target.value);
    }
    const handleHigh = (event) => {
        setHigh(event.target.value);
    }
    const handleBottom = (event) => {
        setBottom(event.target.value);
    }
    const handleKnee = (event) => {
        setKnee(event.target.value);
    }
    const handleWaist = (event) => {
        setWaist(event.target.value);
    }

    function handleChange(event) {
        setImage(event.target.files[0]);
    }

    const retrive = async () => {
        const q = query(collection(db, "client"));
        const c = await getDocs(q);


    }
    useEffect(() => {
        retrive();
    }, [])

    const handelImageUpload = () => {
        setdisable(true);
        console.log("start");
        const storageRef = ref(storage, `/Images/${image.name}`)
        const uploadTask = uploadBytesResumable(storageRef, image);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                setPercent(Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                ));

            },
            (err) => console.log(err),
            async () => {
                await getDownloadURL(uploadTask.snapshot.ref).then((url) => {

                    setImg(url);
                });
            }
        );
        console.log(img);
        setdisable(false);
    }

    const Upload = async (e) => {
        handelImageUpload();
        console.log(id);


        try {

            await addDoc(collection(db, "client", id, "measurement"), {
                type: typ.toLowerCase(),
                len: length,
                botlen: botlen,
                chest: chest,
                tummy: tummy,
                hip: hip,
                shoulder: shoulder,
                sleeves: sleeves,
                collar: collar,
                back: back,
                thigh: thigh,
                high: high,
                bottom: bottom,
                knee: knee,
                wrist: wrist,
                waist: waist,
                calf: calf,
                note: note,
                img: img,
                dateadded: new Date().toLocaleString(),

            });
            await addDoc(collection(db, 'measurement'), {

                dateadded: new Date().toLocaleString(),

            });
            console.log("done, no file")
            setAddedIt(true);



        }
        catch (e) {
            console.log(e);
        }
        setAddedIt(true);
        setImage('');
        setBotlen('');

        setLength('');
        setChest('');
        setTummy('');
        setHip('');
        setShoulder('');
        setSleeves('');
        setCollar('');
        setNote('');
        setWrist('');
        setHigh('');
        setCalf('');
        setThigh('');
        setKnee('');
        setWaist('');
        setBottom('');
        setTimeout(() => {
            setAddedIt(false);
        }, 3000);
        navigate(-1);



    }


    if (type === '1') {
        typ = 'shirt';
        return (

            <Sidebar>
                <div className="flex">
                    <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}><ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} /></div>
                    <div className="block">
                        <div className="text-xl text-black bold">Add measurement </div>
                        <p className='mb-3 text-sm italic text-black dark:text-white-rgba-6 text-opacity-40'>
                            Please fill the Parameters.
                        </p>
                    </div>
                </div>
                <div className='flex flex-col items-center justify-center gap-2 p-5 mt-5 mb-5 border rounded dark:border-white-rgba-1'>

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Length/
                        लम्बाइ</label>
                    <input maxLength={50} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6' type='text' value={length} onChange={handleLength} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Chest/छाती</label>
                    <input maxLength={10} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6' type='text' value={chest} onChange={handleChest} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Tummy/पेट</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={tummy} onChange={handleTummy} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Hip/हिप</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={hip} onChange={handleHip} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Shoulder/कुम</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={shoulder} onChange={handleShoulder} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Sleeves/बाउला </label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={sleeves} onChange={handleSleeves} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Collar/कलर</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={collar} onChange={handleCollar} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Wrist/नाडी</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={wrist} onChange={handleWrist} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Note</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={note} onChange={handleNote} />

                    <input type="file" className='p-2 border rounded-lg' onChange={(e) => { setImage(e.target.files[0]) }} />
                    {addedIt && <Alert variant='outlined' className='my-3 text-sm text-green-500'>Added successfully</Alert>}


                    {percent === 0 ? <></> : <div className='text-sm text-gray-700'>{percent}% upload complete</div>}
                    <div className='flex gap-2'>
                        <div

                            className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkblue hover:bg-linkbluer w-1/8 dark:bg-white-rgba-3 dark:hover:bg-white-rgba-2 hover:bg-black focus:bg-black hover:text-white '
                            onClick={handelImageUpload}
                        >
                            Upload
                        </div>
                        <div


                            className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkblue hover:bg-linkbluer w-1/8 dark:bg-white-rgba-3 dark:hover:bg-white-rgba-2 hover:bg-black focus:bg-black hover:text-white '
                            onClick={Upload}
                        >
                            Add
                        </div>


                        <Link to="/admin/client"><div className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkred hover:bg-linkredder w-1/8 hover:bg-black focus:bg-black hover:text-white' >Back</div></Link>
                    </div>
                </div>
            </Sidebar>
        )




    }
    else if (type === '2') {
        typ = 'coat';
        return (

            <Sidebar>
                <div className="flex">
                    <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}><ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} /></div>
                    <div className="block">
                        <div className="text-xl text-black bold">Add measurement </div>
                        <p className='mb-3 text-sm italic text-black dark:text-white-rgba-6 text-opacity-40'>
                            Please fill the Parameters.
                        </p>
                    </div>
                </div>
                <div className='flex flex-col items-center justify-center gap-2 p-5 mt-5 mb-5 border rounded dark:border-white-rgba-1'>

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Length/
                        लम्बाइ</label>
                    <input maxLength={50} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6' type='text' value={length} onChange={handleLength} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Chest/छाती</label>
                    <input maxLength={10} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6' type='text' value={chest} onChange={handleChest} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Tummy/पेट</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={tummy} onChange={handleTummy} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Hip/हिप</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={hip} onChange={handleHip} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Shoulder/कुम</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={shoulder} onChange={handleShoulder} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Sleeves/बाउला </label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={sleeves} onChange={handleSleeves} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Back</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={back} onChange={handleBack} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Collar/कलर</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={collar} onChange={handleCollar} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Note</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={note} onChange={handleNote} />

                    <input type="file" className='p-2 border rounded-lg' onChange={(e) => { setImage(e.target.files[0]) }} />
                    {addedIt && <Alert variant='outlined' className='my-3 text-sm text-green-500'>Added successfully</Alert>}

                    {percent === 0 ? <></> : <div className='text-sm text-gray-700'>{percent}% upload complete</div>}
                    <div className='flex gap-2'>
                        <div

                            className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkblue hover:bg-linkbluer w-1/8 dark:bg-white-rgba-3 dark:hover:bg-white-rgba-2 hover:bg-black focus:bg-black hover:text-white '
                            onClick={handelImageUpload}
                        >
                            Upload
                        </div>
                        <div

                            className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkblue hover:bg-linkbluer w-1/8 dark:bg-white-rgba-3 dark:hover:bg-white-rgba-2 hover:bg-black focus:bg-black hover:text-white '
                            onClick={Upload}
                        >
                            Add
                        </div>


                        <Link to="/admin/client"><div className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkred hover:bg-linkredder w-1/8 hover:bg-black focus:bg-black hover:text-white' >Back</div></Link>
                    </div>
                </div>
            </Sidebar>
        )




    }

    else if (type === '3') {
        typ = 'waistcoat';
        return (

            <Sidebar>
                <div className="flex">
                    <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}><ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} /></div>
                    <div className="block">
                        <div className="text-xl text-black bold">Add measurement </div>
                        <p className='mb-3 text-sm italic text-black dark:text-white-rgba-6 text-opacity-40'>
                            Please fill the Parameters.
                        </p>
                    </div>
                </div>
                <div className='flex flex-col items-center justify-center gap-2 p-5 mt-5 mb-5 border rounded dark:border-white-rgba-1'>

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Length/
                        लम्बाइ</label>
                    <input maxLength={50} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6 cont' type='text' value={length} onChange={handleLength} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Chest/छाती</label>
                    <input maxLength={10} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6 cont' type='text' value={chest} onChange={handleChest} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Tummy/पेट</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={tummy} onChange={handleTummy} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Hip/हिप</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={hip} onChange={handleHip} />



                    <label className='text-sm font-bold dark:text-white-rgba-7'>Note</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={note} onChange={handleNote} />

                    <input type="file" className='p-2 border rounded-lg' onChange={(e) => { setImage(e.target.files[0]) }} />
                    {addedIt && <Alert variant='outlined' className='my-3 text-sm text-green-500'>Added successfully</Alert>}

                    {percent === 0 ? <></> : <div className='text-sm text-gray-700'>{percent}% upload complete</div>}
                    <div className='flex gap-2'>
                        <div

                            className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkblue hover:bg-linkbluer w-1/8 dark:bg-white-rgba-3 dark:hover:bg-white-rgba-2 hover:bg-black focus:bg-black hover:text-white '
                            onClick={handelImageUpload}
                        >
                            Upload
                        </div>
                        <div

                            className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkblue hover:bg-linkbluer w-1/8 dark:bg-white-rgba-3 dark:hover:bg-white-rgba-2 hover:bg-black focus:bg-black hover:text-white '
                            onClick={Upload}
                        >
                            Add
                        </div>


                        <Link to="/admin/client"><div className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkred hover:bg-linkredder w-1/8 hover:bg-black focus:bg-black hover:text-white' >Back</div></Link>
                    </div>
                </div>
            </Sidebar>
        )




    }
    else if (type === '4') {
        typ = 'kurtha';

        return (

            <Sidebar>
                <div className="flex">
                    <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}><ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} /></div>
                    <div className="block">
                        <div className="text-xl text-black bold">Add measurement </div>
                        <p className='mb-3 text-sm italic text-black dark:text-white-rgba-6 text-opacity-40'>
                            Please fill the Parameters.
                        </p>
                    </div>
                </div>
                <div className='flex flex-col items-center justify-center gap-2 p-5 mt-5 mb-5 border rounded dark:border-white-rgba-1'>

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Length/
                        लम्बाइ</label>
                    <input maxLength={50} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6 cont' type='text' value={length} onChange={handleLength} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Chest/छाती</label>
                    <input maxLength={10} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6 cont' type='text' value={chest} onChange={handleChest} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Tummy/पेट</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={tummy} onChange={handleTummy} />



                    <label className='text-sm font-bold dark:text-white-rgba-7'>Shoulder/कुम</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={shoulder} onChange={handleShoulder} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Sleeves/बाउला </label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={sleeves} onChange={handleSleeves} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Collar/कलर</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={collar} onChange={handleCollar} />



                    <label className='text-sm font-bold dark:text-white-rgba-7'>Suruwal Length/
                        लम्बाइ</label>
                    <input maxLength={50} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6' type='text' value={botlen} onChange={handleBotlen} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Waist/कम्मर</label>
                    <input maxLength={10} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6' type='text' value={waist} onChange={handleWaist} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Thigh/जांघ</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={thigh} onChange={handleThigh} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Hip/हिप</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={hip} onChange={handleHip} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>High</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={high} onChange={handleHigh} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Bottom </label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={bottom} onChange={handleBottom} />


                    <label className='text-sm font-bold dark:text-white-rgba-7'>Note</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={note} onChange={handleNote} />

                    <input type="file" className='p-2 border rounded-lg' onChange={(e) => { setImage(e.target.files[0]) }} />
                    {addedIt && <Alert variant='outlined' className='my-3 text-sm text-green-500'>Added successfully</Alert>}

                    {percent === 0 ? <></> : <div className='text-sm text-gray-700'>{percent}% upload complete</div>}
                    <div className='flex gap-2'>
                        <div

                            className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkblue hover:bg-linkbluer w-1/8 dark:bg-white-rgba-3 dark:hover:bg-white-rgba-2 hover:bg-black focus:bg-black hover:text-white '
                            onClick={handelImageUpload}
                        >
                            Upload
                        </div>
                        <div

                            className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkblue hover:bg-linkbluer w-1/8 dark:bg-white-rgba-3 dark:hover:bg-white-rgba-2 hover:bg-black focus:bg-black hover:text-white '
                            onClick={Upload}
                        >
                            Add
                        </div>


                        <Link to="/admin/client"><div className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkred hover:bg-linkredder w-1/8 hover:bg-black focus:bg-black hover:text-white' >Back</div></Link>
                    </div>
                </div>
            </Sidebar>
        )




    }

    else if (type === '5') {
        typ = 'dawra';
        return (

            <Sidebar>
                <div className="flex">
                    <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}><ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} /></div>
                    <div className="block">
                        <div className="text-xl text-black bold">Add measurement </div>
                        <p className='mb-3 text-sm italic text-black dark:text-white-rgba-6 text-opacity-40'>
                            Please fill the Parameters.
                        </p>
                    </div>
                </div>
                <div className='flex flex-col items-center justify-center gap-2 p-5 mt-5 mb-5 border rounded dark:border-white-rgba-1'>

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Length/
                        लम्बाइ</label>
                    <input maxLength={50} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6 cont' type='text' value={length} onChange={handleLength} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Chest/छाती</label>
                    <input maxLength={10} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6 cont' type='text' value={chest} onChange={handleChest} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Tummy/पेट</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={tummy} onChange={handleTummy} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Wrist/नाडी</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={wrist} onChange={handleWrist} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Shoulder/कुम</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={shoulder} onChange={handleShoulder} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Sleeves/बाउला </label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={sleeves} onChange={handleSleeves} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Collar/कलर</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={collar} onChange={handleCollar} />



                    <label className='text-sm font-bold dark:text-white-rgba-7'>Suruwal Length/
                        लम्बाइ</label>
                    <input maxLength={50} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6' type='text' value={botlen} onChange={handleBotlen} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Calf</label>
                    <input maxLength={50} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6' type='text' value={calf} onChange={handleCalf} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Bottom </label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={bottom} onChange={handleBottom} />



                    <label className='text-sm font-bold dark:text-white-rgba-7'>Note</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={note} onChange={handleNote} />

                    <input type="file" className='p-2 border rounded-lg' onChange={(e) => { setImage(e.target.files[0]) }} />
                    {addedIt && <Alert variant='outlined' className='my-3 text-sm text-green-500'>Added successfully</Alert>}

                    {percent === 0 ? <></> : <div className='text-sm text-gray-700'>{percent}% upload complete</div>}
                    <div className='flex gap-2'>
                        <div

                            className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkblue hover:bg-linkbluer w-1/8 dark:bg-white-rgba-3 dark:hover:bg-white-rgba-2 hover:bg-black focus:bg-black hover:text-white '
                            onClick={handelImageUpload}
                        >
                            Upload
                        </div>
                        <div

                            className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkblue hover:bg-linkbluer w-1/8 dark:bg-white-rgba-3 dark:hover:bg-white-rgba-2 hover:bg-black focus:bg-black hover:text-white '
                            onClick={Upload}
                        >
                            Add
                        </div>


                        <Link to="/admin/client"><div className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkred hover:bg-linkredder w-1/8 hover:bg-black focus:bg-black hover:text-white' >Back</div></Link>
                    </div>
                </div>
            </Sidebar>
        )




    }

    else if (type === '6') {
        typ = 'halfcoat';
        return (

            <Sidebar>
                <div className="flex">
                    <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}><ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} /></div>
                    <div className="block">
                        <div className="text-xl text-black bold">Add measurement </div>
                        <p className='mb-3 text-sm italic text-black dark:text-white-rgba-6 text-opacity-40'>
                            Please fill the Parameters.
                        </p>
                    </div>
                </div>
                <div className='flex flex-col items-center justify-center gap-2 p-5 mt-5 mb-5 border rounded dark:border-white-rgba-1'>

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Length/
                        लम्बाइ</label>
                    <input maxLength={50} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6 cont' type='text' value={length} onChange={handleLength} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Chest/छाती</label>
                    <input maxLength={10} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6 cont' type='text' value={chest} onChange={handleChest} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Tummy/पेट</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={tummy} onChange={handleTummy} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Hip/हिप</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={hip} onChange={handleHip} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Shoulder/कुम</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={shoulder} onChange={handleShoulder} />



                    <label className='text-sm font-bold dark:text-white-rgba-7'>Collar/कलर</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={collar} onChange={handleCollar} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Note</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer cont' type='text' value={note} onChange={handleNote} />

                    <input type="file" className='p-2 border rounded-lg' onChange={(e) => { setImage(e.target.files[0]) }} />
                    {addedIt && <Alert variant='outlined' className='my-3 text-sm text-green-500'>Added successfully</Alert>}

                    {percent === 0 ? <></> : <div className='text-sm text-gray-700'>{percent}% upload complete</div>}
                    <div className='flex gap-2'>
                        <div

                            className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkblue hover:bg-linkbluer w-1/8 dark:bg-white-rgba-3 dark:hover:bg-white-rgba-2 hover:bg-black focus:bg-black hover:text-white '
                            onClick={handelImageUpload}
                        >
                            Upload
                        </div>
                        <div

                            className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkblue hover:bg-linkbluer w-1/8 dark:bg-white-rgba-3 dark:hover:bg-white-rgba-2 hover:bg-black focus:bg-black hover:text-white '
                            onClick={Upload}
                        >
                            Add
                        </div>


                        <Link to="/admin/client"><div className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkred hover:bg-linkredder w-1/8 hover:bg-black focus:bg-black hover:text-white' >Back</div></Link>
                    </div>
                </div>
            </Sidebar>
        )




    }

    else if (type === '7') {
        typ = 'pant';
        return (

            <Sidebar>
                <div className="flex">
                    <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}><ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} /></div>
                    <div className="block">
                        <div className="text-xl text-black bold">Add measurement </div>
                        <p className='mb-3 text-sm italic text-black dark:text-white-rgba-6 text-opacity-40'>
                            Please fill the Parameters.
                        </p>
                    </div>
                </div>
                <div className='flex flex-col items-center justify-center gap-2 p-5 mt-5 mb-5 border rounded dark:border-white-rgba-1'>

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Length/
                        लम्बाइ</label>
                    <input maxLength={50} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6' type='text' value={length} onChange={handleLength} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Waist/कम्मर</label>
                    <input maxLength={10} className='w-1/2 p-2 border rounded border-linkblue dark:border-white-rgba-2 dark:bg-grayer dark:text-white-rgba-6' type='text' value={waist} onChange={handleWaist} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Hip/हिप</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={hip} onChange={handleHip} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Thigh/जांघ</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={thigh} onChange={handleThigh} />


                    <label className='text-sm font-bold dark:text-white-rgba-7'>High</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={high} onChange={handleHigh} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Bottom </label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={bottom} onChange={handleBottom} />

                    <label className='text-sm font-bold dark:text-white-rgba-7'>Knee/घुँडा</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={knee} onChange={handleKnee} />



                    <label className='text-sm font-bold dark:text-white-rgba-7'>Note</label>
                    <input className='w-1/2 p-2 border rounded dark:text-white-rgba-6 border-linkblue dark:border-white-rgba-2 dark:bg-grayer' type='text' value={note} onChange={handleNote} />

                    <input type="file" className='p-2 border rounded-lg' onChange={(e) => { setImage(e.target.files[0]) }} />
                    {addedIt && <Alert variant='outlined' className='my-3 text-sm text-green-500'>Added successfully</Alert>}

                    {percent === 0 ? <></> : <div className='text-sm text-gray-700'>{percent}% upload complete</div>}
                    <div className='flex gap-2'>
                        <div

                            className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkblue hover:bg-linkbluer w-1/8 dark:bg-white-rgba-3 dark:hover:bg-white-rgba-2 hover:bg-black focus:bg-black hover:text-white '
                            onClick={handelImageUpload}
                        >
                            Upload
                        </div>
                        <div

                            className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkblue hover:bg-linkbluer w-1/8 dark:bg-white-rgba-3 dark:hover:bg-white-rgba-2 hover:bg-black focus:bg-black hover:text-white '
                            onClick={Upload}
                        >
                            Add
                        </div>


                        <Link to="/admin/client"><div className='p-2 text-black uppercase border border-black rounded cursor-pointer bg-linkred hover:bg-linkredder w-1/8 hover:bg-black focus:bg-black hover:text-white' >Back</div></Link>
                    </div>
                </div>
            </Sidebar>
        )




    }









    else {
        return (
            <>null</>
        )
    }




}

export default Typeadd