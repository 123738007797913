import React from 'react'
import { useParams } from "react-router-dom"
import Sidebar from '../Sidebar';
import { Link, useNavigate } from 'react-router-dom';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function Addmeasurement() {
  const navigate = useNavigate();
  const { id } = useParams();
  const types = [
    {
      name: 'Shirt',
      index: '1'

    },
    {
      name: 'Coat',
      index: '2'

    },
    {
      name: 'Waistcoat',
      index: '3'

    },
    {
      name: 'Kurtha',
      index: '4'

    },
    {
      name: 'Dawra',
      index: '5'

    },
    {
      name: 'Halfcoat',
      index: '6'

    },
    {
      name: 'Pants',
      index: '7'

    },


  ]


  return (
    <Sidebar>


      <div className="flex">
        <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}><ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} /></div>
        <div className="block">
          <div className="text-xl text-black bold">Add measurement </div>
          <p className='mb-3 text-sm italic text-black dark:text-white-rgba-6 text-opacity-40'>
            Please select the type of measurement to add
          </p>
        </div>

      </div>


      <div className="">
        <TableContainer className="w-1/2 ">
          <Table className="w-1/2" sx={{ Width: 100 }} aria-label="simple table">
            <TableHead >
              <TableRow className='font-'>
                <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} align="left">Actions</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {types.map((typ) => (
                <TableRow
                  key={typ.name}

                >
                  <TableCell component="th" scope="row">
                    {typ.name}
                  </TableCell>
                  <TableCell align="left"><div className="px-3 py-1 mr-1 text-white rounded w-fit bg-slate-700 hover:bg-slate-900"><Link to={`/admin/measurement/${id}/${typ.index}`}>Add</Link></div></TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>



    </Sidebar>

  )
}

export default Addmeasurement