import React from 'react'
import Sidebar from '../Sidebar'
import { useAuth } from '../../contexts/AuthContext';
function Setting() {
  const { logout } = useAuth();
  async function lOut(e) {
    await logout();

  }
  return (
    <>
      <Sidebar>
        <div className="text-xl text-black bold">Settings</div>
        <p className='mb-3 text-sm italic text-black dark:text-white-rgba-6 text-opacity-40'>
          Smart - The Ultimate Apparel TMS V2 Update 2024
        </p>
        <div

          onClick={() => lOut()}
          className='px-2 py-1 text-sm text-white rounded-sm cursor-pointer bg-slate-700 hover:bg-slate-900 mt-30 w-fit '
        >

          Log Out
        </div>

      </Sidebar>
    </>
  )
}

export default Setting