import React from 'react'
import Navbar from '../../navbar'
import Features from './Features'
import Footer from '../Footer'
import Hero from './Hero'
import Intouch from './intouch'
import Testis from './testimonials'

function Home() {
  return (
    <>
    <Hero/>
    <Features/>
    <Intouch/>
    <Testis/>
    <Footer/>
    </>

  )
}

export default Home