import React, { useState, useEffect } from 'react'
import Sidebar from '../Sidebar'
import { db } from '../../firebase'
import { addDoc, collection, doc, getDocs, onSnapshot, orderBy, query, setDoc } from 'firebase/firestore';
import { useContext } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { Chart as ChartJS, CategoryScale, LinearScale, Title, Tooltip, Legend, LineElement, PointElement, Filler, RadialLinearScale, ArcElement } from 'chart.js';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { SyncLoader } from 'react-spinners';

ChartJS.register(
    LineElement,
    ArcElement,
    RadialLinearScale,
    PointElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Filler,
    // Legend,
);
const Dashboard = () => {
    const [client, setClient] = useState(0);
    const [data, setData] = useState([])
    const [clientData, setclientData] = useState({
        labels: [],
        datasets: []
    });
    const [measurement, setmeasurement] = useState(0);
    const retrive = async () => {
        const q = query(collection(db, "client"));
        const c = await getDocs(q);
        setClient(c.docs.length);
        const q2 = query(collection(db, "measurement"));
        const m = await getDocs(q2);
        setmeasurement(m.docs.length);
    }
    useEffect(() => {
        retrive();
    }, [])



    return (
        <Sidebar>
            <>
                <div className="text-xl text-black bold">Dashboard</div>
                <p className='mb-3 text-sm italic text-black dark:text-white-rgba-6 text-opacity-40'>
                    Hey admin, hope you're having a wonderful day!
                </p>
                <div className='w-full overflow-hidden  lg:ml-5 h-4/12 lg:flex sm:block'>
                    <div className='w-full p-3 mt-5 mb-2 rounded-md  lg:mx-5 cont lg:w-80'>
                        <div className='text-sm text-slate-800 '>Total clients</div>
                        <div className='pl-2 text-4xl text-slate-900 '>{client}</div>
                    </div>
                    <div className='w-full p-3 mt-5 mb-2 rounded-md cont lg:mx-5 lg:w-80'>
                        <div className='text-sm text-slate-800 '>Total measurement</div>
                        <div className='pl-2 text-4xl text-slate-900'>{measurement}</div>
                    </div>
                    <div className='w-full p-3 mt-5 mb-2 rounded-md cont lg:mx-5 lg:w-80'>
                        <div className='text-sm text-slate-800 '>Server Status</div>
                        <div className='pl-2 text-4xl text-slate-900'>^-^</div>
                    </div>


                </div>
                <div className='items-center justify-center w-full overflow-hidden rounded-md h-4/12'>
                    <ClientTrend />
                    <ServerStat />

                </div>


            </>
        </Sidebar>
    )
}

export default Dashboard

const ClientTrend = (props) => {



    // Data fetching 

    const [lastData, setLastData] = React.useState([]);
    const [count, setCount] = React.useState([]);
    const [dataDuration, setDataDuration] = React.useState(20);

    const { currentUser } = useAuth();
    const userId = currentUser.uid;

    const handleDurChange = (e) => {
        setDataDuration(e.target.value)
    }

    useEffect(() => {
        const q = query(collection(db, "client"), orderBy("dateadded"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            var d = [];
            var data = [];
            var elements = {};
            var e = {};
            var a, b;


            querySnapshot.forEach((doc) => {
                elements = {
                    id: doc.id,
                    ...doc.data()
                }

                data.push(elements);

                // for(let i = 0; i < data.length; i++){
                //     var c;
                //     for (let j = i+1;j<data.length;j++){
                //          a = moment(data[i].dateadded).format('MM/DD')
                //          b= moment(data[j].dateadded).format('MM/DD')
                //         if(a===b){

                //             c=c+1;
                //             data.splice(j-1,1);


                //         }
                //     }
                //     e = {
                //         date: a,
                //         count:c,
                //     }
                //     d.push(e);
                // }
                // console.log(d);
                setCount(data);
                setLastData(data.slice(0, dataDuration));
            }
                , [])
        }
            , [])
    }, [dataDuration])

    // Data fetching end

    const [chartData, setChartData] = React.useState({
        datasets: [],
    });

    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        setChartData({
            labels: lastData.map(element => moment(element.dateadded).format('MM/DD')).reverse(),
            datasets: [{
                // fill: true,
                label: 'Clients',
                data: lastData.map(element => element.count),
                borderColor: `${'#00000'}`,



                tension: 0.5,
                showLine: true,
                borderWidth: 1,

                // backgroundColor: gradient
            }]
        });
        setChartOptions({

            responsive: true,
            plugins: {
                // legend: {
                //     position: "top"
                // }
            },
            title: {
                display: true,
                text: 'Client Trend',

            }

        });
    }
        , [lastData, dataDuration])


    return (
        <div className='flex flex-col items-center justify-between w-full gap-2 p-4 mt-2 mb-3 border rounded-md lg:w-9/12 lg:mx-5 dark:border-white-rgba-1 cont lg:ml-9 ' >
            <div className='flex justify-start gap-3 text-left'>
                <p className='text-black text-opacity-80 text-semibold dark:text-white-rgba-5'>Client Trend</p>
            </div>


            <Line options={chartOptions} data={chartData} color="blue" />
        </div >
    )
}

const ServerStat = () => {


    const { currentUser } = useAuth();
    const userId = currentUser.uid;

    // random number with min and max
    function randomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const [oneStat, setOneStat] = React.useState(90);
    const [twoStat, setTwoStat] = React.useState(90);
    const [threeStat, setThreeStat] = React.useState(90);
    const [fourStat, setFourStat] = React.useState(90);

    const MINUTE_MS = 10000;

    useEffect(() => {
        const interval = setInterval(() => {
            setOneStat(randomNumber(80, 100));
            setTwoStat(randomNumber(87, 100));
            setThreeStat(randomNumber(75, 100));
            setFourStat(randomNumber(95, 100));
        }, MINUTE_MS);

        return () => clearInterval(interval);
    }, [])

    // random numbers every minute


    const FakeDataVis = (props) => {
        return (
            <CircularProgressbar
                className='w-28'
                value={props.stat}
                text={`${props.stat}%`}
                styles={buildStyles({
                    textSize: '20px',
                    pathColor: 'black',
                    textColor: 'black',
                    textAlign: 'center',

                    trailColor: '#f7faff',
                })}
            />
        )
    }

    return (
        <div className='flex flex-col items-center justify-between w-full gap-2 p-4 border rounded-md lg:w-9/12 dark:border-white-rgba-1 cont lg:ml-9 '>
            <p className='text-black text-opacity-80 text-semibold dark:text-white-rgba-5'>SERVER PERFORMANCE</p>

            <div className='w-[100%] flex items-center justify-center gap-5'>
                <FakeDataVis stat={oneStat} />
                <FakeDataVis stat={twoStat} />
                <FakeDataVis stat={threeStat} />
                <FakeDataVis stat={fourStat} />
            </div>

            <div className='flex items-center '>
                <p className='flex gap-4 text-black dark:text-white-rgba-5 text-opacity-80 '>
                    Listening realtime updates
                    <SyncLoader size={5} color={'black'} loading={true} />
                </p>
            </div>
        </div>
    )
}
