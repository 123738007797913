import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { collection, query, onSnapshot, doc, setDoc, getDoc, getDocs, deleteDoc } from 'firebase/firestore';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Modal, Stack } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Sidebar from '../Sidebar';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

function Viewclient() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [client, setClient] = useState(null);
    const [row, setRow] = useState([]);
    const [opn, setOpn] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [updated, setUpdated] = useState(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 200,
        height: 110,
        p: 2,
    };

    const getData = async () => {
        setLoading(true);
        setFetched(false);

        const measurementsQuery = query(collection(db, "client", id, "measurement"));
        const clientDocRef = doc(db, "client", id);

        try {
            const [measurementsSnapshot, clientSnapshot] = await Promise.all([
                getDocs(measurementsQuery),
                getDoc(clientDocRef)
            ]);

            let measurementsData = [];
            measurementsSnapshot.forEach(doc => {
                measurementsData.push({
                    id: doc.id,
                    type: doc.data().type,
                    note: doc.data().note,
                    dateadded: doc.data().dateadded
                });
            });

            const clientData = clientSnapshot.exists() ? clientSnapshot.data() : null;

            setRow(measurementsData);
            setFetched(true);

            if (clientData) {
                setClient(clientData);
            } else {
                setError("Client not found.");
            }

        } catch (error) {
            console.error("Error fetching data: ", error);
            setError("Failed to load data.");
        } finally {
            setLoading(false);
        }
    };

    const finalDeletion = (measurementId) => {
        const finalDelete = async () => {
            try {
                const contactRef = doc(db, "client", id, "measurement", measurementId);
                await deleteDoc(contactRef);
                setDeleted(true);
                setRow([]);
                getData();
            } catch (e) {
                console.error(e);
            } finally {
                setTimeout(() => {
                    setDeleted(false);
                }, 3000);
                handleClse();
            }
        };
        finalDelete();
    };

    const editMeasurement = async (e) => {
        const field = e.field;
        const value = e.value;
        try {
            await setDoc(doc(db, "client", id, "measurement", e.id), {
                [field]: value,
            }, { merge: true });
            setUpdated(true);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getData();
    }, [id]);

    const handleOpn = (id) => {
        setOpn(id);
    };

    const handleClse = () => {
        setOpn(false);
    };

    const columns = [
        { field: 'type', headerName: 'Name', width: 299, align: 'left', editable: true },
        { field: 'dateadded', headerName: 'Date Added', type: 'string', width: 200, align: 'left', headerAlign: 'left', editable: true },
        { field: 'note', headerName: 'Note', width: 200, align: 'left', headerAlign: 'left', editable: true },
        {
            field: 'actions', headerName: 'Actions', width: 210, align: 'left', headerAlign: 'left', sortable: false,
            renderCell: (cellValues) => {
                return (
                    <>
                        <div className="px-3 py-1 mr-1 text-white rounded bg-slate-700 hover:bg-slate-900">
                            <Link to={`/admin/measurement/viewmeasurement/${id}/${cellValues.row.id}`}>View</Link>
                        </div>
                        <div className="px-3 py-1 mr-1 text-white rounded bg-slate-700 hover:bg-slate-900">
                            <Link to={`/admin/measurement/updatemeasurement/${id}/${cellValues.row.id}`}>Update</Link>
                        </div>
                        <div className="px-3 py-1 m-1 text-white bg-red-700 rounded cursor-pointer hover:bg-red-900" onClick={() => handleOpn(cellValues.row.id)}>Delete</div>
                        <Modal
                            open={opn === cellValues.row.id}
                            onClose={handleClse}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style} className='rounded cont'>
                                <Typography id="modal-modal-title" className='mb-3 text-sm italic text-black dark:text-white-rgba-6 text-opacity-80'>
                                    Confirm Delete
                                </Typography>
                                <div className="flex">
                                    <div className="w-2/4 px-2 py-1 m-1 text-center text-white bg-red-700 rounded cursor-pointer hover:bg-red-900" onClick={() => finalDeletion(cellValues.row.id)}>Delete</div>
                                    <div className="w-2/4 px-2 py-1 m-1 text-center text-white rounded cursor-pointer bg-slate-700 hover:bg-slate-900" onClick={handleClse}>Close</div>
                                </div>
                            </Box>
                        </Modal>
                    </>
                );
            }
        },
    ];

    return (
        <Sidebar>
            <>
                <div className="flex">
                    <div className="p-1 mt-2 mr-5 bg-black rounded-md cursor-pointer hover:bg-gray-700 w-fit h-fit" onClick={() => navigate(-1)}>
                        <ArrowBackIosRoundedIcon fontSize='small' sx={{ color: 'white' }} />
                    </div>
                    <div className="block">
                        <div className="text-xl text-black bold">Client's Measurements</div>
                        <p className='mb-3 text-sm italic text-black dark:text-white-rgba-6 text-opacity-40'>
                            View all of client's measurements
                        </p>
                    </div>
                </div>

                <div className="py-2 my-4 rounded-lg">
                    {loading ? (
                        <p className="text-gray-500 animate-pulse">Loading client details...</p>
                    ) : error ? (
                        <p className="text-red-500">{error}</p>
                    ) : client ? (
                        <div className="space-y-4">
                            <h2 className="text-2xl font-bold text-gray-800">{client.name}</h2>
                            <p className="text-gray-600">
                                <span className="font-semibold">Contact:</span> {client.contact}
                            </p>
                            <p className="text-gray-600">
                                <span className="font-semibold">Address:</span> {client.address}
                            </p>
                        </div>
                    ) : (
                        <p className="text-gray-500">No client details found.</p>
                    )}
                </div>


                <Link to={`/admin/measurement/${id}`}>
                    <div className='px-2 py-1 text-sm text-white uppercase rounded cursor-pointer bg-slate-700 hover:bg-slate-900 mt-30 w-fit '>
                        NEW Measurement
                    </div>
                </Link>

                <div className='max-h-[80%] h-full items-left '>
                    <DataGrid
                        autoHeight
                        headerHeight={20}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'id', sort: 'desc' }],
                            },
                        }}
                        rowsPerPageOptions={[20, 50, 100]}
                        rowHeight={35}
                        rows={row}
                        columns={columns}
                        disableSelectionOnClick={true}
                        className={`mb-2`}
                        loading={loading}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                        components={{
                            Toolbar: GridToolbar,
                            NoRowsOverlay: () => (
                                <Stack className='text-center' height="100%" alignItems="center" justifyContent="center">
                                    <p className='mt-10 text-sm text-gray-600'>No measurements found. Refresh or add new measurements.</p>
                                </Stack>
                            ),
                        }}
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                            }
                        }}
                        onCellEditCommit={(e) => {
                            editMeasurement(e);
                        }}
                        getRowClassName={(e) =>
                            e.indexRelativeToCurrentPage % 2 === 0 ? 'bg-black bg-opacity-[0.025] dark:bg-grayer dark:text-white-rgba-9' : 'bg-white bg-opacity-[0.02] dark:bg-white dark:bg-opacity-5 dark:text-white-rgba-9'
                        }
                        sx={{
                            boxShadow: 0,
                            border: 0,
                            padding: 0,
                            margin: 0,
                        }}
                    />
                </div>
            </>
        </Sidebar>
    );
}

export default Viewclient;
